import React  from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';

import ReviewImage1 from '../assets/Testimonials/Image1-min2.webp';
import ReviewImage2 from '../assets/Testimonials/Image2-min2.webp';
import ReviewImage3 from '../assets/Testimonials/Image3-min2.webp';
import ReviewImage4 from '../assets/Testimonials/Image4-min2.webp';
import ReviewImage5 from '../assets/Testimonials/Image5-min2.webp';
import ReviewImage6 from '../assets/Testimonials/Image6-min2.webp';

const Testimonials = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Customer Testimonials</h1>

                <Socials />

                <div className="container-slim">
                    <img src={ReviewImage1} width="35%" alt="Arizona Black Walnut #1" />
                </div>
                <br /><br />
                <div className="container-slim">
                    <img src={ReviewImage2} width="30%" alt="Arizona Black Walnut #2" />
                    &nbsp;&nbsp;
                    <img src={ReviewImage3} width="30%" alt="Arizona Black Walnut #3" />
                </div>
                <br /><br />
                <div className="protocol-container" >
                    I highly recommend Lia for your kitchen remodel! Originally, I was going to do a basic kitchen remodel with 
                    store bought countertops but when Lia and her crew installed my cabinets, Lia and I had the same idea... match the countertops 
                    to my beautiful breakfast nook (see photo). She NAILED it! And on top of that they made me a wine rack, lazy susan, and shelf 
                    for an heriloom cut out of the Arizona black walnut that was left over! Thank you Lia, David, and Harvey!!
                    <br /><br />
                    Rick
                </div>
                <br /><br />

                <div className="container-slim">
                    <img src={ReviewImage5} width="35%" alt="Kitchen Cabinet Door Replacement" />
                </div>
                <br /><br />
                <div className="protocol-container" >
                    They were fantastic to work with! I needed a single cabinet door replaced and I had reached out to several places and no one would do it. 
                    Lia was eager to help and she came to my home and took the cabinet door. In a very timely manner they brought me back a beautiful replica of 
                    the original. I highly recommend them! I am getting my cabinets painted next week so it is just natural wood until then. Their work appears 
                    to be flawless. If you have something small in your kitchen that needs replaced do not hesitate to use them!
                    <br /><br />
                    Tatiana
                </div>
                <br /><br />

                <div className="container-slim">
                    <img src={ReviewImage4}  width="30%" alt="Floating Shelf #1" />
                </div>
                <br /><br />
                <div className="protocol-container" >
                    I am so incredibly impressed with Lia’s design. Not only is she extremely talented, she is also trustworthy and personable. She really took the 
                    time to listen and understand my style. Lia knows where to find unique products & materials. I highly recommend Lia & David. The are both very 
                    gifted and exceeded all of my expectations.
                    <br /><br />
                    Tanya
                </div>
                <br /><br />

                <div className="container-slim">
                    <img src={ReviewImage6}  width="35%" alt="Kitchen Design" />
                </div>
                <br /><br />
                <div className="protocol-container" >
                    Love my new kitchen.  The new color and granite countertops are light and bright.  The backsplash reminds me of sea glass.  
                    The design could not be better and just what I had in mind.
                    <br /><br />
                    Stephanie
                </div>
                <br /><br />

            </div>
        </div>

    );

};

export default Testimonials;
