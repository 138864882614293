import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import NavbarComponent from './components/Navbar'
import FooterComponent from './components/Footer'

import Home from './routes';
import Woodworking from './routes/woodworking';
import Toys from './routes/toys';
import Workshop from './routes/workshop';
import Gallery from './routes/gallery';
import Store from './routes/store';
import AboutUs from './routes/about';
import ContactUs from './routes/contact';
import Testimonials from './routes/testimonials';

import './styles/index.css'

function App() {

    return (

        <Router>
            <NavbarComponent />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/woodworking' element={<Woodworking />} />
                <Route path='/toys' element={<Toys />} />
                <Route path='/workshop' element={<Workshop />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/store' element={<Store />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/testimonials' element={<Testimonials />} />
            </Routes>
            <FooterComponent />
        </Router>
    
    )

}

export default App
