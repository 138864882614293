import React from 'react';

import '../styles/Components.css';

import CoverImage from '../assets/design-image-min2.webp';

import Socials from '../components/Socials';
import Slideshow from '../components/Slideshow';

const Home = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Welcome to Crystal Wood Elements</h1>

                <Socials />

                <div className='oval-container'>
                    <img src={CoverImage} alt='Arizona Black Walnut Countertops' /> 
                </div>

                <Slideshow />

            </div>

            <div className="container-small">
                <p>
                    We offer complete interior design services through our <a href="https://hhdaz.com" target="_blank" rel="noreferrer">Helping Hand Designs</a> site.
                </p>
            </div>


        </div>

    );

};

export default Home;
