import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import Designer from "../assets/designer.webp";

const AboutUs = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>About Us</h1>

                <Socials />

                <div className="protocol-container" >
                    Design plays an essential role in encouraging a serene ambiance to enjoy relaxing in the place you call home.  
                    Your space should nurture a relaxing and inviting sense of peace while telling a story about who you are.
                    This company was started to help clients modify an already existing space to fit their lifestyle and bring their vision to life all while 
                    offering an exclusive product and experience along the way.The unique feature of our business is that we can create custom woodworking pieces 
                    to incorporate into design client’s spaces to personalize it and make it their own.  We specialize in custom furniture and epoxy work.  
                    From custom fine heirloom quality furniture to traditional built-ins, we offer our customers an exclusive product and experience every step of the way.
                </div>

                <h2>Designer Bio</h2>

                <div className="protocol-container" >
                    Lia graduated from Arizona State University’s Herberger Institute for Design & the Arts in 2018 with a B.A. in design studies/design management.  
                    She uses Sketch-Up to help clients visualize their newly designed space and is also proficient in Adobe Creative Suite software.  She is especially 
                    passionate about innovative and adaptive design, having earned 21 credit hours in special education and working closely with the elderly and those 
                    with disabilities.  Lia is also enthusiastic about bringing together the community of artisans and started the Arizona Artists & Woodworkers Facebook 
                    group in 2020 for local entrepreneurs and to showcase their work, network with other local creators, grow their businesses and expand their abilities
                    or resources.
                </div>
                
                <div className="protocol-container" align="center">
                    <br></br>
                    <img src={Designer} width="60%" height="auto" alt="Designer - Lia"/>
                </div>

            </div>
        </div>

    );

};

export default AboutUs;
