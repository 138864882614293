import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import ImageGrid from '../components/ImageGrid'

const Gallery = () => {

    return (

        <div className='components'>
            <div className='container'>

            <h1>Project Photo Gallery</h1>

            <Socials />

            <div className='protocol-container'>
                <ImageGrid />
            </div>

            </div>
        </div>

    );

};

export default Gallery;
