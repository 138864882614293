
import '../styles/ImageGrid.css';

/* Full Size Images for Link */
import Toy001 from "../assets/Gallery/TOY-001.webp";
import Toy002 from "../assets/Gallery/TOY-002.webp";
import Toy003 from "../assets/Gallery/TOY-003.webp";
import Toy004 from "../assets/Gallery/TOY-004.webp";
import Toy005 from "../assets/Gallery/TOY-005.webp";
import Toy006 from "../assets/Gallery/TOY-006.webp";
import Toy007 from "../assets/Gallery/TOY-007.webp";
import Toy008 from "../assets/Gallery/TOY-008.webp";
import Toy009 from "../assets/Gallery/TOY-009.webp";
import Toy010 from "../assets/Gallery/TOY-010.webp";
import Toy011 from "../assets/Gallery/TOY-011.webp";
import Toy012 from "../assets/Gallery/TOY-012.webp";
import Toy013 from "../assets/Gallery/TOY-013.webp";
import Toy014 from "../assets/Gallery/TOY-014.webp";
import Toy015 from "../assets/Gallery/TOY-015.webp";
import Toy016 from "../assets/Gallery/TOY-016.webp";
import Toy017 from "../assets/Gallery/TOY-017.webp";
import Toy018 from "../assets/Gallery/TOY-018.webp";

import Toy019 from "../assets/Gallery/TOY-019.webp";
import Toy020 from "../assets/Gallery/TOY-020.webp";
import Toy021 from "../assets/Gallery/TOY-021.webp";
import Toy022 from "../assets/Gallery/TOY-022.webp";
import Toy023 from "../assets/Gallery/TOY-023.webp";
import Toy024 from "../assets/Gallery/TOY-024.webp";
import Toy025 from "../assets/Gallery/TOY-025.webp";
import Toy026 from "../assets/Gallery/TOY-026.webp";
import Toy027 from "../assets/Gallery/TOY-027.webp";
import Toy028 from "../assets/Gallery/TOY-028.webp";
import Toy029 from "../assets/Gallery/TOY-029.webp";
import Toy030 from "../assets/Gallery/TOY-030.webp";
import Toy031 from "../assets/Gallery/TOY-031.webp";
import Toy032 from "../assets/Gallery/TOY-032.webp";
import Toy033 from "../assets/Gallery/TOY-033.webp";
import Toy034 from "../assets/Gallery/TOY-034.webp";
import Toy035 from "../assets/Gallery/TOY-035.webp";
import Toy036 from "../assets/Gallery/TOY-036.webp";

import Toy037 from "../assets/Gallery/TOY-037.webp";
import Toy038 from "../assets/Gallery/TOY-038.webp";
import Toy039 from "../assets/Gallery/TOY-039.webp";
import Toy040 from "../assets/Gallery/TOY-040.webp";
import Toy041 from "../assets/Gallery/TOY-041.webp";
import Toy042 from "../assets/Gallery/TOY-042.webp";
import Toy043 from "../assets/Gallery/TOY-043.webp";
import Toy044 from "../assets/Gallery/TOY-044.webp";
import Toy045 from "../assets/Gallery/TOY-045.webp";
import Toy046 from "../assets/Gallery/TOY-046.webp";
import Toy047 from "../assets/Gallery/TOY-047.webp";
import Toy048 from "../assets/Gallery/TOY-048.webp";
import Toy049 from "../assets/Gallery/TOY-049.webp";
import Toy050 from "../assets/Gallery/TOY-050.webp";
import Toy051 from "../assets/Gallery/TOY-051.webp";
import Toy052 from "../assets/Gallery/TOY-052.webp";
import Toy053 from "../assets/Gallery/TOY-053.webp";
import Toy054 from "../assets/Gallery/TOY-054.webp";

import Toy055 from "../assets/Gallery/TOY-055.webp";
import Toy056 from "../assets/Gallery/TOY-056.webp";
import Toy057 from "../assets/Gallery/TOY-057.webp";
import Toy058 from "../assets/Gallery/TOY-058.webp";
import Toy059 from "../assets/Gallery/TOY-059.webp";
import Toy060 from "../assets/Gallery/TOY-060.webp";
import Toy061 from "../assets/Gallery/TOY-061.webp";
import Toy062 from "../assets/Gallery/TOY-062.webp";
import Toy063 from "../assets/Gallery/TOY-063.webp";
import Toy064 from "../assets/Gallery/TOY-064.webp";
import Toy065 from "../assets/Gallery/TOY-065.webp";
import Toy066 from "../assets/Gallery/TOY-066.webp";
import Toy067 from "../assets/Gallery/TOY-067.webp";
import Toy068 from "../assets/Gallery/TOY-068.webp";
import Toy069 from "../assets/Gallery/TOY-069.webp";
import Toy070 from "../assets/Gallery/TOY-070.webp";
import Toy071 from "../assets/Gallery/TOY-071.webp";
import Toy072 from "../assets/Gallery/TOY-072.webp";
import Toy073 from "../assets/Gallery/TOY-073.webp";
import Toy074 from "../assets/Gallery/TOY-074.webp";
import Toy075 from "../assets/Gallery/TOY-075.webp";
import Toy076 from "../assets/Gallery/TOY-076.webp";
import Toy077 from "../assets/Gallery/TOY-077.webp";
import Toy078 from "../assets/Gallery/TOY-078.webp";
import Toy079 from "../assets/Gallery/TOY-079.webp";
import Toy080 from "../assets/Gallery/TOY-080.webp";

/* Minimized Images for Gallery Display */
import Toy001Min from "../assets/Gallery/TOY-001-min2.webp";
import Toy002Min from "../assets/Gallery/TOY-002-min2.webp";
import Toy003Min from "../assets/Gallery/TOY-003-min2.webp";
import Toy004Min from "../assets/Gallery/TOY-004-min2.webp";
import Toy005Min from "../assets/Gallery/TOY-005-min2.webp";
import Toy006Min from "../assets/Gallery/TOY-006-min2.webp";
import Toy007Min from "../assets/Gallery/TOY-007-min2.webp";
import Toy008Min from "../assets/Gallery/TOY-008-min2.webp";
import Toy009Min from "../assets/Gallery/TOY-009-min2.webp";
import Toy010Min from "../assets/Gallery/TOY-010-min2.webp";
import Toy011Min from "../assets/Gallery/TOY-011-min2.webp";
import Toy012Min from "../assets/Gallery/TOY-012-min2.webp";
import Toy013Min from "../assets/Gallery/TOY-013-min2.webp";
import Toy014Min from "../assets/Gallery/TOY-014-min2.webp";
import Toy015Min from "../assets/Gallery/TOY-015-min2.webp";
import Toy016Min from "../assets/Gallery/TOY-016-min2.webp";
import Toy017Min from "../assets/Gallery/TOY-017-min2.webp";
import Toy018Min from "../assets/Gallery/TOY-018-min2.webp";

import Toy019Min from "../assets/Gallery/TOY-019-min2.webp";
import Toy020Min from "../assets/Gallery/TOY-020-min2.webp";
import Toy021Min from "../assets/Gallery/TOY-021-min2.webp";
import Toy022Min from "../assets/Gallery/TOY-022-min2.webp";
import Toy023Min from "../assets/Gallery/TOY-023-min2.webp";
import Toy024Min from "../assets/Gallery/TOY-024-min2.webp";
import Toy025Min from "../assets/Gallery/TOY-025-min2.webp";
import Toy026Min from "../assets/Gallery/TOY-026-min2.webp";
import Toy027Min from "../assets/Gallery/TOY-027-min2.webp";
import Toy028Min from "../assets/Gallery/TOY-028-min2.webp";
import Toy029Min from "../assets/Gallery/TOY-029-min2.webp";
import Toy030Min from "../assets/Gallery/TOY-030-min2.webp";
import Toy031Min from "../assets/Gallery/TOY-031-min2.webp";
import Toy032Min from "../assets/Gallery/TOY-032-min2.webp";
import Toy033Min from "../assets/Gallery/TOY-033-min2.webp";
import Toy034Min from "../assets/Gallery/TOY-034-min2.webp";
import Toy035Min from "../assets/Gallery/TOY-035-min2.webp";
import Toy036Min from "../assets/Gallery/TOY-036-min2.webp";

import Toy037Min from "../assets/Gallery/TOY-037-min2.webp";
import Toy038Min from "../assets/Gallery/TOY-038-min2.webp";
import Toy039Min from "../assets/Gallery/TOY-039-min2.webp";
import Toy040Min from "../assets/Gallery/TOY-040-min2.webp";
import Toy041Min from "../assets/Gallery/TOY-041-min2.webp";
import Toy042Min from "../assets/Gallery/TOY-042-min2.webp";
import Toy043Min from "../assets/Gallery/TOY-043-min2.webp";
import Toy044Min from "../assets/Gallery/TOY-044-min2.webp";
import Toy045Min from "../assets/Gallery/TOY-045-min2.webp";
import Toy046Min from "../assets/Gallery/TOY-046-min2.webp";
import Toy047Min from "../assets/Gallery/TOY-047-min2.webp";
import Toy048Min from "../assets/Gallery/TOY-048-min2.webp";
import Toy049Min from "../assets/Gallery/TOY-049-min2.webp";
import Toy050Min from "../assets/Gallery/TOY-050-min2.webp";
import Toy051Min from "../assets/Gallery/TOY-051-min2.webp";
import Toy052Min from "../assets/Gallery/TOY-052-min2.webp";
import Toy053Min from "../assets/Gallery/TOY-053-min2.webp";
import Toy054Min from "../assets/Gallery/TOY-054-min2.webp";

import Toy055Min from "../assets/Gallery/TOY-055-min2.webp";
import Toy056Min from "../assets/Gallery/TOY-056-min2.webp";
import Toy057Min from "../assets/Gallery/TOY-057-min2.webp";
import Toy058Min from "../assets/Gallery/TOY-058-min2.webp";
import Toy059Min from "../assets/Gallery/TOY-059-min2.webp";
import Toy060Min from "../assets/Gallery/TOY-060-min2.webp";
import Toy061Min from "../assets/Gallery/TOY-061-min2.webp";
import Toy062Min from "../assets/Gallery/TOY-062-min2.webp";
import Toy063Min from "../assets/Gallery/TOY-063-min2.webp";
import Toy064Min from "../assets/Gallery/TOY-064-min2.webp";
import Toy065Min from "../assets/Gallery/TOY-065-min2.webp";
import Toy066Min from "../assets/Gallery/TOY-066-min2.webp";
import Toy067Min from "../assets/Gallery/TOY-067-min2.webp";
import Toy068Min from "../assets/Gallery/TOY-068-min2.webp";
import Toy069Min from "../assets/Gallery/TOY-069-min2.webp";
import Toy070Min from "../assets/Gallery/TOY-070-min2.webp";
import Toy071Min from "../assets/Gallery/TOY-071-min2.webp";
import Toy072Min from "../assets/Gallery/TOY-072-min2.webp";
import Toy073Min from "../assets/Gallery/TOY-073-min2.webp";
import Toy074Min from "../assets/Gallery/TOY-074-min2.webp";
import Toy075Min from "../assets/Gallery/TOY-075-min2.webp";
import Toy076Min from "../assets/Gallery/TOY-076-min2.webp";
import Toy077Min from "../assets/Gallery/TOY-077-min2.webp";
import Toy078Min from "../assets/Gallery/TOY-078-min2.webp";
import Toy079Min from "../assets/Gallery/TOY-079-min2.webp";
import Toy080Min from "../assets/Gallery/TOY-080-min2.webp";

const ToyGallery = () => {

    return (

        <div className="row"> 
            <div className="column">
                <a href={Toy001} target="_blank" rel="noreferrer">
                    <img src={Toy001Min} alt="Toy Gallery 1"/>
                </a>
                <a href={Toy002} target="_blank" rel="noreferrer">
                    <img src={Toy002Min} alt="Toy Gallery 2"/>
                </a>
                <a href={Toy003} target="_blank" rel="noreferrer">
                    <img src={Toy003Min} alt="Toy Gallery 3"/>
                </a>
                <a href={Toy004} target="_blank" rel="noreferrer">
                    <img src={Toy004Min} alt="Toy Gallery 4"/>
                </a>
                <a href={Toy005} target="_blank" rel="noreferrer">
                    <img src={Toy005Min} alt="Toy Gallery 5"/>
                </a>
                <a href={Toy006} target="_blank" rel="noreferrer">
                    <img src={Toy006Min} alt="Toy Gallery 6"/>
                </a>
                <a href={Toy007} target="_blank" rel="noreferrer">
                    <img src={Toy007Min} alt="Toy Gallery 7"/>
                </a>
                <a href={Toy008} target="_blank" rel="noreferrer">
                    <img src={Toy008Min} alt="Toy Gallery 8"/>
                </a>
                <a href={Toy009} target="_blank" rel="noreferrer">
                    <img src={Toy009Min} alt="Toy Gallery 9"/>
                </a>
                <a href={Toy010} target="_blank" rel="noreferrer">
                    <img src={Toy010Min} alt="Toy Gallery 10"/>
                </a>
                <a href={Toy011} target="_blank" rel="noreferrer">
                    <img src={Toy011Min} alt="Toy Gallery 11"/>
                </a>
                <a href={Toy012} target="_blank" rel="noreferrer">
                    <img src={Toy012Min} alt="Toy Gallery 12"/>
                </a>
                <a href={Toy013} target="_blank" rel="noreferrer">
                    <img src={Toy013Min} alt="Toy Gallery 13"/>
                </a>
                <a href={Toy014} target="_blank" rel="noreferrer">
                    <img src={Toy014Min} alt="Toy Gallery 14"/>
                </a>
                <a href={Toy015} target="_blank" rel="noreferrer">
                    <img src={Toy015Min} alt="Toy Gallery 15"/>
                </a>
                <a href={Toy016} target="_blank" rel="noreferrer">
                    <img src={Toy016Min} alt="Toy Gallery 16"/>
                </a>
                <a href={Toy017} target="_blank" rel="noreferrer">
                    <img src={Toy017Min} alt="Toy Gallery 17"/>
                </a>
                <a href={Toy018} target="_blank" rel="noreferrer">
                    <img src={Toy018Min} alt="Toy Gallery 18"/>
                </a>
                <a href={Toy019} target="_blank" rel="noreferrer">
                    <img src={Toy019Min} alt="Toy Gallery 19"/>
                </a>
                <a href={Toy020} target="_blank" rel="noreferrer">
                    <img src={Toy020Min} alt="Toy Gallery 20"/>
                </a>
                <a href={Toy021} target="_blank" rel="noreferrer">
                    <img src={Toy021Min} alt="Toy Gallery 21"/>
                </a>
            </div>

            <div className="column">
                <a href={Toy022} target="_blank" rel="noreferrer">
                    <img src={Toy022Min} alt="Toy Gallery 22"/>
                </a>
                <a href={Toy023} target="_blank" rel="noreferrer">
                    <img src={Toy023Min} alt="Toy Gallery 23"/>
                </a>
                <a href={Toy024} target="_blank" rel="noreferrer">
                    <img src={Toy024Min} alt="Toy Gallery 24"/>
                </a>
                <a href={Toy025} target="_blank" rel="noreferrer">
                    <img src={Toy025Min} alt="Toy Gallery 25"/>
                </a>
                <a href={Toy026} target="_blank" rel="noreferrer">
                    <img src={Toy026Min} alt="Toy Gallery 26"/>
                </a>
                <a href={Toy027} target="_blank" rel="noreferrer">
                    <img src={Toy027Min} alt="Toy Gallery 27"/>
                </a>
                <a href={Toy028} target="_blank" rel="noreferrer">
                    <img src={Toy028Min} alt="Toy Gallery 28"/>
                </a>
                <a href={Toy029} target="_blank" rel="noreferrer">
                    <img src={Toy029Min} alt="Toy Gallery 29"/>
                </a>
                <a href={Toy030} target="_blank" rel="noreferrer">
                    <img src={Toy030Min} alt="Toy Gallery 30"/>
                </a>
                <a href={Toy031} target="_blank" rel="noreferrer">
                    <img src={Toy031Min} alt="Toy Gallery 31"/>
                </a>
                <a href={Toy032} target="_blank" rel="noreferrer">
                    <img src={Toy032Min} alt="Toy Gallery 32"/>
                </a>
                <a href={Toy033} target="_blank" rel="noreferrer">
                    <img src={Toy033Min} alt="Toy Gallery 33"/>
                </a>
                <a href={Toy034} target="_blank" rel="noreferrer">
                    <img src={Toy034Min} alt="Toy Gallery 34"/>
                </a>
                <a href={Toy035} target="_blank" rel="noreferrer">
                    <img src={Toy035Min} alt="Toy Gallery 35"/>
                </a>
                <a href={Toy036} target="_blank" rel="noreferrer">
                    <img src={Toy036Min} alt="Toy Gallery 36"/>
                </a>
                <a href={Toy037} target="_blank" rel="noreferrer">
                    <img src={Toy037Min} alt="Toy Gallery 37"/>
                </a>
                <a href={Toy038} target="_blank" rel="noreferrer">
                    <img src={Toy038Min} alt="Toy Gallery 38"/>
                </a>
                <a href={Toy039} target="_blank" rel="noreferrer">
                    <img src={Toy039Min} alt="Toy Gallery 39"/>
                </a>
                <a href={Toy040} target="_blank" rel="noreferrer">
                    <img src={Toy040Min} alt="Toy Gallery 40"/>
                </a>
                <a href={Toy041} target="_blank" rel="noreferrer">
                    <img src={Toy041Min} alt="Toy Gallery 41"/>
                </a>
            </div>

            <div className="column">
                <a href={Toy042} target="_blank" rel="noreferrer">
                    <img src={Toy042Min} alt="Toy Gallery 42"/>
                </a>
                <a href={Toy043} target="_blank" rel="noreferrer">
                    <img src={Toy043Min} alt="Toy Gallery 43"/>
                </a>
                <a href={Toy044} target="_blank" rel="noreferrer">
                    <img src={Toy044Min} alt="Toy Gallery 44"/>
                </a>
                <a href={Toy045} target="_blank" rel="noreferrer">
                    <img src={Toy045Min} alt="Toy Gallery 45"/>
                </a>
                <a href={Toy046} target="_blank" rel="noreferrer">
                    <img src={Toy046Min} alt="Toy Gallery 46"/>
                </a>
                <a href={Toy047} target="_blank" rel="noreferrer">
                    <img src={Toy047Min} alt="Toy Gallery 47"/>
                </a>
                <a href={Toy048} target="_blank" rel="noreferrer">
                    <img src={Toy048Min} alt="Toy Gallery 48"/>
                </a>
                <a href={Toy049} target="_blank" rel="noreferrer">
                    <img src={Toy049Min} alt="Toy Gallery 49"/>
                </a>
                <a href={Toy050} target="_blank" rel="noreferrer">
                    <img src={Toy050Min} alt="Toy Gallery 50"/>
                </a>
                <a href={Toy051} target="_blank" rel="noreferrer">
                    <img src={Toy051Min} alt="Toy Gallery 51"/>
                </a>
                <a href={Toy052} target="_blank" rel="noreferrer">
                    <img src={Toy052Min} alt="Toy Gallery 52"/>
                </a>
                <a href={Toy053} target="_blank" rel="noreferrer">
                    <img src={Toy053Min} alt="Toy Gallery 53"/>
                </a>
                <a href={Toy054} target="_blank" rel="noreferrer">
                    <img src={Toy054Min} alt="Toy Gallery 54"/>
                </a>
                <a href={Toy055} target="_blank" rel="noreferrer">
                    <img src={Toy055Min} alt="Toy Gallery 55"/>
                </a>
                <a href={Toy056} target="_blank" rel="noreferrer">
                    <img src={Toy056Min} alt="Toy Gallery 56"/>
                </a>
                <a href={Toy057} target="_blank" rel="noreferrer">
                    <img src={Toy057Min} alt="Toy Gallery 57"/>
                </a>
                <a href={Toy058} target="_blank" rel="noreferrer">
                    <img src={Toy058Min} alt="Toy Gallery 58"/>
                </a>
                <a href={Toy059} target="_blank" rel="noreferrer">
                    <img src={Toy059Min} alt="Toy Gallery 59"/>
                </a>
                <a href={Toy060} target="_blank" rel="noreferrer">
                    <img src={Toy060Min} alt="Toy Gallery 60"/>
                </a>
                <a href={Toy061} target="_blank" rel="noreferrer">
                    <img src={Toy061Min} alt="Toy Gallery 61"/>
                </a>
                <a href={Toy062} target="_blank" rel="noreferrer">
                    <img src={Toy062Min} alt="Toy Gallery 62"/>
                </a>
            </div>

            <div className="column">
                <a href={Toy063} target="_blank" rel="noreferrer">
                    <img src={Toy063Min} alt="Toy Gallery 63"/>
                </a>
                <a href={Toy064} target="_blank" rel="noreferrer">
                    <img src={Toy064Min} alt="Toy Gallery 64"/>
                </a>
                <a href={Toy065} target="_blank" rel="noreferrer">
                    <img src={Toy065Min} alt="Toy Gallery 65"/>
                </a>
                <a href={Toy066} target="_blank" rel="noreferrer">
                    <img src={Toy066Min} alt="Toy Gallery 66"/>
                </a>
                <a href={Toy067} target="_blank" rel="noreferrer">
                    <img src={Toy067Min} alt="Toy Gallery 67"/>
                </a>
                <a href={Toy068} target="_blank" rel="noreferrer">
                    <img src={Toy068Min} alt="Toy Gallery 68"/>
                </a>
                <a href={Toy069} target="_blank" rel="noreferrer">
                    <img src={Toy069Min} alt="Toy Gallery 69"/>
                </a>
                <a href={Toy070} target="_blank" rel="noreferrer">
                    <img src={Toy070Min} alt="Toy Gallery 70"/>
                </a>
                <a href={Toy071} target="_blank" rel="noreferrer">
                    <img src={Toy071Min} alt="Toy Gallery 71"/>
                </a>
                <a href={Toy072} target="_blank" rel="noreferrer">
                    <img src={Toy072Min} alt="Toy Gallery 72"/>
                </a>
                <a href={Toy073} target="_blank" rel="noreferrer">
                    <img src={Toy073Min} alt="Toy Gallery 73"/>
                </a>
                <a href={Toy074} target="_blank" rel="noreferrer">
                    <img src={Toy074Min} alt="Toy Gallery 74"/>
                </a>
                <a href={Toy075} target="_blank" rel="noreferrer">
                    <img src={Toy075Min} alt="Toy Gallery 75"/>
                </a>
                <a href={Toy076} target="_blank" rel="noreferrer">
                    <img src={Toy076Min} alt="Toy Gallery 76"/>
                </a>
                <a href={Toy077} target="_blank" rel="noreferrer">
                    <img src={Toy077Min} alt="Toy Gallery 77"/>
                </a>
                <a href={Toy078} target="_blank" rel="noreferrer">
                    <img src={Toy078Min} alt="Toy Gallery 78"/>
                </a>
                <a href={Toy079} target="_blank" rel="noreferrer">
                    <img src={Toy079Min} alt="Toy Gallery 79"/>
                </a>
                <a href={Toy080} target="_blank" rel="noreferrer">
                    <img src={Toy080Min} alt="Toy Gallery 80"/>
                </a>
            </div>
        </div>

    );

};

export default ToyGallery;
