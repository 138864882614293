import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import ShopGallery from '../components/ShopGallery'

const Workshop = () => {

    return (

        <div className='components'>
            <div className='container'>

            <h1>Shop Photos</h1>

            <Socials />

            <div className='protocol-container'>
                <ShopGallery />
            </div>

            </div>
        </div>

    );

};

export default Workshop;
