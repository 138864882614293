import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';

const ContactUs = () => {

    return (

        <div className='components'>
            <div className='container'>

            <h1>Contact Us</h1>

            <Socials />

            </div>

            <div className="container-small">
                <p>
                    Contact Us at any of the Following Options :<br /><br />
                    
                    Phone via <a href="tel:1-703-581-7062">1-703-581-7062</a><br />
                    Text via <a href="sms:1-703-581-7062">1-703-581-7062</a><br />
                    Email via <a href="mailto:info@crystalwoodelements.com">info@crystalwoodelements.com</a><br /><br />

                    Social media options :<br /><br />
                    Instagram via <a href="https://instagram.com/Crystal_Wood_Elements" target="_blank" rel="noreferrer">Crystal_Wood_Elements</a><br />
                    Facebook via <a href="https://facebook.com/CrystalWoodElements" target="_blank" rel="noreferrer">CrystalWoodElements</a><br /><br />

                    Social media messaging options :<br /><br />
                    Instagram Messages via <a href="https://ig.me/m/Crystal_Wood_Elements" target="_blank" rel="noreferrer">Crystal_Wood_Elements</a><br />
                    Facebook Messenger via <a href="https://m.me/CrystalWoodElements" target="_blank" rel="noreferrer">CrystalWoodElements</a><br /><br />
                </p>
            </div>
        </div>

    );

};

export default ContactUs;
