import React, { Component } from "react";
import { Slide } from "react-slideshow-image";

import '../styles/Slideshow.css';

import "react-slideshow-image/dist/styles.css";

import SS1 from '../assets/Slideshow/SSImage1-min2.webp';
import SS2 from '../assets/Slideshow/SSImage2-min2.webp';
import SS3 from '../assets/Slideshow/SSImage3-min2.webp';
import SS4 from '../assets/Slideshow/SSImage4-min2.webp';
import SS5 from '../assets/Slideshow/SSImage5-min2.webp';
import SS6 from '../assets/Slideshow/SSImage6-min2.webp';

class Slideshow extends Component {
    constructor() {
        super();
        this.slideRef = React.createRef();
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.state = {
        current: 0
        };
    }

    back() {
        this.slideRef.current.goBack();
    }

    next() {
        this.slideRef.current.goNext();
    }

    render() {
        const properties = {
        duration: 3000,
        autoplay: true,
        transitionDuration: 2500,
        arrows: true,
        infinite: true,
        easing: "ease",
        indicators: (i) => <div className="indicator">{i + 1}</div>
    };
   
    const slideImages = [
        { 
            slideshow: SS1
        },
        { 
            slideshow: SS2
        },
        { 
            slideshow: SS3
        },
        { 
            slideshow: SS4
        },
        { 
            slideshow: SS5
        },
        { 
            slideshow: SS6
        }
    ];

    return (
        <div className="App">
            <div className="slide-container">
                <Slide ref={this.slideRef} {...properties}>
                    {slideImages.map((each, index) => (
                    <div key={index} className="each-slide">
                        <img className="lazy" src={each.slideshow} alt="Finished Projects" />
                    </div>
                    ))}
                </Slide>
            </div>
        </div>
        );
    }
    
}

export default Slideshow;
