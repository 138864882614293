
import '../styles/ImageGrid.css';

/* Full Size Images for Link */
import Gallery001 from "../assets/Gallery/CWE-001.webp";
import Gallery002 from "../assets/Gallery/CWE-002.webp";
import Gallery003 from "../assets/Gallery/CWE-003.webp";
import Gallery004 from "../assets/Gallery/CWE-004.webp";
import Gallery005 from "../assets/Gallery/CWE-005.webp";
import Gallery006 from "../assets/Gallery/CWE-006.webp";
import Gallery007 from "../assets/Gallery/CWE-007.webp";
import Gallery008 from "../assets/Gallery/CWE-008.webp";
import Gallery009 from "../assets/Gallery/CWE-009.webp";
import Gallery010 from "../assets/Gallery/CWE-010.webp";
import Gallery011 from "../assets/Gallery/CWE-011.webp";
import Gallery012 from "../assets/Gallery/CWE-012.webp";
import Gallery013 from "../assets/Gallery/CWE-013.webp";
import Gallery014 from "../assets/Gallery/CWE-014.webp";
import Gallery015 from "../assets/Gallery/CWE-015.webp";
import Gallery016 from "../assets/Gallery/CWE-016.webp";
import Gallery017 from "../assets/Gallery/CWE-017.webp";
import Gallery018 from "../assets/Gallery/CWE-018.webp";
import Gallery019 from "../assets/Gallery/CWE-019.webp";

import Gallery020 from "../assets/Gallery/CWE-020.webp";
import Gallery021 from "../assets/Gallery/CWE-021.webp";
import Gallery022 from "../assets/Gallery/CWE-022.webp";
import Gallery023 from "../assets/Gallery/CWE-023.webp";
import Gallery024 from "../assets/Gallery/CWE-024.webp";
import Gallery025 from "../assets/Gallery/CWE-025.webp";
import Gallery026 from "../assets/Gallery/CWE-026.webp";
import Gallery027 from "../assets/Gallery/CWE-027.webp";
import Gallery028 from "../assets/Gallery/CWE-028.webp";
import Gallery029 from "../assets/Gallery/CWE-029.webp";
import Gallery030 from "../assets/Gallery/CWE-030.webp";
import Gallery031 from "../assets/Gallery/CWE-031.webp";
import Gallery032 from "../assets/Gallery/CWE-032.webp";
import Gallery033 from "../assets/Gallery/CWE-033.webp";
import Gallery034 from "../assets/Gallery/CWE-034.webp";
import Gallery035 from "../assets/Gallery/CWE-035.webp";
import Gallery038 from "../assets/Gallery/CWE-038.webp";
import Gallery039 from "../assets/Gallery/CWE-039.webp";
import Gallery040 from "../assets/Gallery/CWE-040.webp";
import Gallery041 from "../assets/Gallery/CWE-041.webp";
import Gallery042 from "../assets/Gallery/CWE-042.webp";

import Gallery043 from "../assets/Gallery/CWE-043.webp";
import Gallery044 from "../assets/Gallery/CWE-044.webp";
import Gallery045 from "../assets/Gallery/CWE-045.webp";
import Gallery046 from "../assets/Gallery/CWE-046.webp";
import Gallery047 from "../assets/Gallery/CWE-047.webp";
import Gallery048 from "../assets/Gallery/CWE-048.webp";
import Gallery049 from "../assets/Gallery/CWE-049.webp";
import Gallery050 from "../assets/Gallery/CWE-050.webp";
import Gallery051 from "../assets/Gallery/CWE-051.webp";
import Gallery052 from "../assets/Gallery/CWE-052.webp";
import Gallery053 from "../assets/Gallery/CWE-053.webp";
import Gallery054 from "../assets/Gallery/CWE-054.webp";
import Gallery055 from "../assets/Gallery/CWE-055.webp";
import Gallery056 from "../assets/Gallery/CWE-056.webp";
import Gallery057 from "../assets/Gallery/CWE-057.webp";
import Gallery058 from "../assets/Gallery/CWE-058.webp";
import Gallery059 from "../assets/Gallery/CWE-059.webp";
import Gallery060 from "../assets/Gallery/CWE-060.webp";

import Gallery061 from "../assets/Gallery/CWE-061.webp";
import Gallery062 from "../assets/Gallery/CWE-062.webp";
import Gallery063 from "../assets/Gallery/CWE-063.webp";
import Gallery064 from "../assets/Gallery/CWE-064.webp";
import Gallery065 from "../assets/Gallery/CWE-065.webp";
import Gallery066 from "../assets/Gallery/CWE-066.webp";
import Gallery067 from "../assets/Gallery/CWE-067.webp";
import Gallery068 from "../assets/Gallery/CWE-068.webp";
import Gallery069 from "../assets/Gallery/CWE-069.webp";
import Gallery070 from "../assets/Gallery/CWE-070.webp";
import Gallery071 from "../assets/Gallery/CWE-071.webp";
import Gallery072 from "../assets/Gallery/CWE-072.webp";
import Gallery073 from "../assets/Gallery/CWE-073.webp";
import Gallery074 from "../assets/Gallery/CWE-074.webp";
import Gallery075 from "../assets/Gallery/CWE-075.webp";
import Gallery076 from "../assets/Gallery/CWE-076.webp";
import Gallery077 from "../assets/Gallery/CWE-077.webp";
import Gallery078 from "../assets/Gallery/CWE-078.webp";

/* Minimized Images for Gallery Display */
import Gallery001Min from "../assets/Gallery/CWE-001-min2.webp";
import Gallery002Min from "../assets/Gallery/CWE-002-min2.webp";
import Gallery003Min from "../assets/Gallery/CWE-003-min2.webp";
import Gallery004Min from "../assets/Gallery/CWE-004-min2.webp";
import Gallery005Min from "../assets/Gallery/CWE-005-min2.webp";
import Gallery006Min from "../assets/Gallery/CWE-006-min2.webp";
import Gallery007Min from "../assets/Gallery/CWE-007-min2.webp";
import Gallery008Min from "../assets/Gallery/CWE-008-min2.webp";
import Gallery009Min from "../assets/Gallery/CWE-009-min2.webp";
import Gallery010Min from "../assets/Gallery/CWE-010-min2.webp";
import Gallery011Min from "../assets/Gallery/CWE-011-min2.webp";
import Gallery012Min from "../assets/Gallery/CWE-012-min2.webp";
import Gallery013Min from "../assets/Gallery/CWE-013-min2.webp";
import Gallery014Min from "../assets/Gallery/CWE-014-min2.webp";
import Gallery015Min from "../assets/Gallery/CWE-015-min2.webp";
import Gallery016Min from "../assets/Gallery/CWE-016-min2.webp";
import Gallery017Min from "../assets/Gallery/CWE-017-min2.webp";
import Gallery018Min from "../assets/Gallery/CWE-018-min2.webp";
import Gallery019Min from "../assets/Gallery/CWE-019-min2.webp";

import Gallery020Min from "../assets/Gallery/CWE-020-min2.webp";
import Gallery021Min from "../assets/Gallery/CWE-021-min2.webp";
import Gallery022Min from "../assets/Gallery/CWE-022-min2.webp";
import Gallery023Min from "../assets/Gallery/CWE-023-min2.webp";
import Gallery024Min from "../assets/Gallery/CWE-024-min2.webp";
import Gallery025Min from "../assets/Gallery/CWE-025-min2.webp";
import Gallery026Min from "../assets/Gallery/CWE-026-min2.webp";
import Gallery027Min from "../assets/Gallery/CWE-027-min2.webp";
import Gallery028Min from "../assets/Gallery/CWE-028-min2.webp";
import Gallery029Min from "../assets/Gallery/CWE-029-min2.webp";
import Gallery030Min from "../assets/Gallery/CWE-030-min2.webp";
import Gallery031Min from "../assets/Gallery/CWE-031-min2.webp";
import Gallery032Min from "../assets/Gallery/CWE-032-min2.webp";
import Gallery033Min from "../assets/Gallery/CWE-033-min2.webp";
import Gallery034Min from "../assets/Gallery/CWE-034-min2.webp";
import Gallery035Min from "../assets/Gallery/CWE-035-min2.webp";
import Gallery038Min from "../assets/Gallery/CWE-038-min2.webp";
import Gallery039Min from "../assets/Gallery/CWE-039-min2.webp";
import Gallery040Min from "../assets/Gallery/CWE-040-min2.webp";
import Gallery041Min from "../assets/Gallery/CWE-041-min2.webp";
import Gallery042Min from "../assets/Gallery/CWE-042-min2.webp";

import Gallery043Min from "../assets/Gallery/CWE-043-min2.webp";
import Gallery044Min from "../assets/Gallery/CWE-044-min2.webp";
import Gallery045Min from "../assets/Gallery/CWE-045-min2.webp";
import Gallery046Min from "../assets/Gallery/CWE-046-min2.webp";
import Gallery047Min from "../assets/Gallery/CWE-047-min2.webp";
import Gallery048Min from "../assets/Gallery/CWE-048-min2.webp";
import Gallery049Min from "../assets/Gallery/CWE-049-min2.webp";
import Gallery050Min from "../assets/Gallery/CWE-050-min2.webp";
import Gallery051Min from "../assets/Gallery/CWE-051-min2.webp";
import Gallery052Min from "../assets/Gallery/CWE-052-min2.webp";
import Gallery053Min from "../assets/Gallery/CWE-053-min2.webp";
import Gallery054Min from "../assets/Gallery/CWE-054-min2.webp";
import Gallery055Min from "../assets/Gallery/CWE-055-min2.webp";
import Gallery056Min from "../assets/Gallery/CWE-056-min2.webp";
import Gallery057Min from "../assets/Gallery/CWE-057-min2.webp";
import Gallery058Min from "../assets/Gallery/CWE-058-min2.webp";
import Gallery059Min from "../assets/Gallery/CWE-059-min2.webp";
import Gallery060Min from "../assets/Gallery/CWE-060-min2.webp";

import Gallery061Min from "../assets/Gallery/CWE-061-min2.webp";
import Gallery062Min from "../assets/Gallery/CWE-062-min2.webp";
import Gallery063Min from "../assets/Gallery/CWE-063-min2.webp";
import Gallery064Min from "../assets/Gallery/CWE-064-min2.webp";
import Gallery065Min from "../assets/Gallery/CWE-065-min2.webp";
import Gallery066Min from "../assets/Gallery/CWE-066-min2.webp";
import Gallery067Min from "../assets/Gallery/CWE-067-min2.webp";
import Gallery068Min from "../assets/Gallery/CWE-068-min2.webp";
import Gallery069Min from "../assets/Gallery/CWE-069-min2.webp";
import Gallery070Min from "../assets/Gallery/CWE-070-min2.webp";
import Gallery071Min from "../assets/Gallery/CWE-071-min2.webp";
import Gallery072Min from "../assets/Gallery/CWE-072-min2.webp";
import Gallery073Min from "../assets/Gallery/CWE-073-min2.webp";
import Gallery074Min from "../assets/Gallery/CWE-074-min2.webp";
import Gallery075Min from "../assets/Gallery/CWE-075-min2.webp";
import Gallery076Min from "../assets/Gallery/CWE-076-min2.webp";
import Gallery077Min from "../assets/Gallery/CWE-077-min2.webp";
import Gallery078Min from "../assets/Gallery/CWE-078-min2.webp";

const ImageGrid = () => {

    return (

        <div className="row"> 
            <div className="column">
                <a href={Gallery001} target="_blank" rel="noreferrer">
                    <img src={Gallery001Min} alt="Gallery 1"/>
                </a>
                <a href={Gallery002} target="_blank" rel="noreferrer">
                    <img src={Gallery002Min} alt="Gallery 2"/>
                </a>
                <a href={Gallery003} target="_blank" rel="noreferrer">
                    <img src={Gallery003Min} alt="Gallery 3"/>
                </a>
                <a href={Gallery004} target="_blank" rel="noreferrer">
                    <img src={Gallery004Min} alt="Gallery 4"/>
                </a>
                <a href={Gallery005} target="_blank" rel="noreferrer">
                    <img src={Gallery005Min} alt="Gallery 5"/>
                </a>
                <a href={Gallery006} target="_blank" rel="noreferrer">
                    <img src={Gallery006Min} alt="Gallery 6"/>
                </a>
                <a href={Gallery007} target="_blank" rel="noreferrer">
                    <img src={Gallery007Min} alt="Gallery 7"/>
                </a>
                <a href={Gallery008} target="_blank" rel="noreferrer">
                    <img src={Gallery008Min} alt="Gallery 8"/>
                </a>
                <a href={Gallery009} target="_blank" rel="noreferrer">
                    <img src={Gallery009Min} alt="Gallery 9"/>
                </a>
                <a href={Gallery010} target="_blank" rel="noreferrer">
                    <img src={Gallery010Min} alt="Gallery 10"/>
                </a>
                <a href={Gallery011} target="_blank" rel="noreferrer">
                    <img src={Gallery011Min} alt="Gallery 11"/>
                </a>
                <a href={Gallery012} target="_blank" rel="noreferrer">
                    <img src={Gallery012Min} alt="Gallery 12"/>
                </a>
                <a href={Gallery013} target="_blank" rel="noreferrer">
                    <img src={Gallery013Min} alt="Gallery 13"/>
                </a>
                <a href={Gallery014} target="_blank" rel="noreferrer">
                    <img src={Gallery014Min} alt="Gallery 14"/>
                </a>
                <a href={Gallery015} target="_blank" rel="noreferrer">
                    <img src={Gallery015Min} alt="Gallery 15"/>
                </a>
                <a href={Gallery016} target="_blank" rel="noreferrer">
                    <img src={Gallery016Min} alt="Gallery 16"/>
                </a>
                <a href={Gallery017} target="_blank" rel="noreferrer">
                    <img src={Gallery017Min} alt="Gallery 17"/>
                </a>
                <a href={Gallery018} target="_blank" rel="noreferrer">
                    <img src={Gallery018Min} alt="Gallery 18"/>
                </a>
                <a href={Gallery019} target="_blank" rel="noreferrer">
                    <img src={Gallery019Min} alt="Gallery 19"/>
                </a>
            </div>

            <div className="column">
                <a href={Gallery020} target="_blank" rel="noreferrer">
                    <img src={Gallery020Min} alt="Gallery 20"/>
                </a>
                <a href={Gallery021} target="_blank" rel="noreferrer">
                    <img src={Gallery021Min} alt="Gallery 21"/>
                </a>
                <a href={Gallery022} target="_blank" rel="noreferrer">
                    <img src={Gallery022Min} alt="Gallery 22"/>
                </a>
                <a href={Gallery023} target="_blank" rel="noreferrer">
                    <img src={Gallery023Min} alt="Gallery 23"/>
                </a>
                <a href={Gallery024} target="_blank" rel="noreferrer">
                    <img src={Gallery024Min} alt="Gallery 24"/>
                </a>
                <a href={Gallery025} target="_blank" rel="noreferrer">
                    <img src={Gallery025Min} alt="Gallery 25"/>
                </a>
                <a href={Gallery026} target="_blank" rel="noreferrer">
                    <img src={Gallery026Min} alt="Gallery 26"/>
                </a>
                <a href={Gallery027} target="_blank" rel="noreferrer">
                    <img src={Gallery027Min} alt="Gallery 27"/>
                </a>
                <a href={Gallery028} target="_blank" rel="noreferrer">
                    <img src={Gallery028Min} alt="Gallery 28"/>
                </a>
                <a href={Gallery029} target="_blank" rel="noreferrer">
                    <img src={Gallery029Min} alt="Gallery 29"/>
                </a>
                <a href={Gallery030} target="_blank" rel="noreferrer">
                    <img src={Gallery030Min} alt="Gallery 30"/>
                </a>
                <a href={Gallery031} target="_blank" rel="noreferrer">
                    <img src={Gallery031Min} alt="Gallery 31"/>
                </a>
                <a href={Gallery032} target="_blank" rel="noreferrer">
                    <img src={Gallery032Min} alt="Gallery 32"/>
                </a>
                <a href={Gallery033} target="_blank" rel="noreferrer">
                    <img src={Gallery033Min} alt="Gallery 33"/>
                </a>
                <a href={Gallery034} target="_blank" rel="noreferrer">
                    <img src={Gallery034Min} alt="Gallery 34"/>
                </a>
                <a href={Gallery035} target="_blank" rel="noreferrer">
                    <img src={Gallery035Min} alt="Gallery 35"/>
                </a>
                <a href={Gallery038} target="_blank" rel="noreferrer">
                    <img src={Gallery038Min} alt="Gallery 38"/>
                </a>
                <a href={Gallery039} target="_blank" rel="noreferrer">
                    <img src={Gallery039Min} alt="Gallery 39"/>
                </a>
                <a href={Gallery040} target="_blank" rel="noreferrer">
                    <img src={Gallery040Min} alt="Gallery 40"/>
                </a>
                <a href={Gallery041} target="_blank" rel="noreferrer">
                    <img src={Gallery041Min} alt="Gallery 41"/>
                </a>
                <a href={Gallery042} target="_blank" rel="noreferrer">
                    <img src={Gallery042Min} alt="Gallery 42"/>
                </a>
            </div>

            <div className="column">
                <a href={Gallery043} target="_blank" rel="noreferrer">
                    <img src={Gallery043Min} alt="Gallery 43"/>
                </a>
                <a href={Gallery044} target="_blank" rel="noreferrer">
                    <img src={Gallery044Min} alt="Gallery 44"/>
                </a>
                <a href={Gallery045} target="_blank" rel="noreferrer">
                    <img src={Gallery045Min} alt="Gallery 45"/>
                </a>
                <a href={Gallery046} target="_blank" rel="noreferrer">
                    <img src={Gallery046Min} alt="Gallery 46"/>
                </a>
                <a href={Gallery047} target="_blank" rel="noreferrer">
                    <img src={Gallery047Min} alt="Gallery 47"/>
                </a>
                <a href={Gallery048} target="_blank" rel="noreferrer">
                    <img src={Gallery048Min} alt="Gallery 48"/>
                </a>
                <a href={Gallery049} target="_blank" rel="noreferrer">
                    <img src={Gallery049Min} alt="Gallery 49"/>
                </a>
                <a href={Gallery050} target="_blank" rel="noreferrer">
                    <img src={Gallery050Min} alt="Gallery 50"/>
                </a>
                <a href={Gallery051} target="_blank" rel="noreferrer">
                    <img src={Gallery051Min} alt="Gallery 51"/>
                </a>
                <a href={Gallery052} target="_blank" rel="noreferrer">
                    <img src={Gallery052Min} alt="Gallery 52"/>
                </a>
                <a href={Gallery053} target="_blank" rel="noreferrer">
                    <img src={Gallery053Min} alt="Gallery 53"/>
                </a>
                <a href={Gallery054} target="_blank" rel="noreferrer">
                    <img src={Gallery054Min} alt="Gallery 54"/>
                </a>
                <a href={Gallery055} target="_blank" rel="noreferrer">
                    <img src={Gallery055Min} alt="Gallery 55"/>
                </a>
                <a href={Gallery056} target="_blank" rel="noreferrer">
                    <img src={Gallery056Min} alt="Gallery 56"/>
                </a>
                <a href={Gallery057} target="_blank" rel="noreferrer">
                    <img src={Gallery057Min} alt="Gallery 57"/>
                </a>
                <a href={Gallery058} target="_blank" rel="noreferrer">
                    <img src={Gallery058Min} alt="Gallery 58"/>
                </a>
                <a href={Gallery059} target="_blank" rel="noreferrer">
                    <img src={Gallery059Min} alt="Gallery 59"/>
                </a>
                <a href={Gallery060} target="_blank" rel="noreferrer">
                    <img src={Gallery060Min} alt="Gallery 60"/>
                </a>
            </div>

            <div className="column">
                <a href={Gallery061} target="_blank" rel="noreferrer">
                    <img src={Gallery061Min} alt="Gallery 61"/>
                </a>
                <a href={Gallery062} target="_blank" rel="noreferrer">
                    <img src={Gallery062Min} alt="Gallery 62"/>
                </a>
                <a href={Gallery063} target="_blank" rel="noreferrer">
                    <img src={Gallery063Min} alt="Gallery 63"/>
                </a>
                <a href={Gallery064} target="_blank" rel="noreferrer">
                    <img src={Gallery064Min} alt="Gallery 64"/>
                </a>
                <a href={Gallery065} target="_blank" rel="noreferrer">
                    <img src={Gallery065Min} alt="Gallery 65"/>
                </a>
                <a href={Gallery066} target="_blank" rel="noreferrer">
                    <img src={Gallery066Min} alt="Gallery 66"/>
                </a>
                <a href={Gallery067} target="_blank" rel="noreferrer">
                    <img src={Gallery067Min} alt="Gallery 67"/>
                </a>
                <a href={Gallery068} target="_blank" rel="noreferrer">
                    <img src={Gallery068Min} alt="Gallery 68"/>
                </a>
                <a href={Gallery069} target="_blank" rel="noreferrer">
                    <img src={Gallery069Min} alt="Gallery 69"/>
                </a>
                <a href={Gallery070} target="_blank" rel="noreferrer">
                    <img src={Gallery070Min} alt="Gallery 70"/>
                </a>
                <a href={Gallery071} target="_blank" rel="noreferrer">
                    <img src={Gallery071Min} alt="Gallery 71"/>
                </a>
                <a href={Gallery072} target="_blank" rel="noreferrer">
                    <img src={Gallery072Min} alt="Gallery 72"/>
                </a>
                <a href={Gallery073} target="_blank" rel="noreferrer">
                    <img src={Gallery073Min} alt="Gallery 73"/>
                </a>
                <a href={Gallery074} target="_blank" rel="noreferrer">
                    <img src={Gallery074Min} alt="Gallery 74"/>
                </a>
                <a href={Gallery075} target="_blank" rel="noreferrer">
                    <img src={Gallery075Min} alt="Gallery 75"/>
                </a>
                <a href={Gallery076} target="_blank" rel="noreferrer">
                    <img src={Gallery076Min} alt="Gallery 76"/>
                </a>
                <a href={Gallery077} target="_blank" rel="noreferrer">
                    <img src={Gallery077Min} alt="Gallery 77"/>
                </a>
                <a href={Gallery078} target="_blank" rel="noreferrer">
                    <img src={Gallery078Min} alt="Gallery 78"/>
                </a>

            </div>
        </div>

    );

};

export default ImageGrid;
