import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';
import ToyGallery from '../components/ToyGallery'

const WoodenToys = () => {

    return (

        <div className='components'>
            <div className='container'>

            <h1>Wooden Toy Gallery</h1>

            <Socials />

            <div className='protocol-container'>
                <ToyGallery />
            </div>

            </div>
        </div>

    );

};

export default WoodenToys;
