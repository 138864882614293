import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';

const CustomWoodworking = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Custom Woodworking</h1>

                <Socials />

                <div className="container-slim" >
                    <br /><br />
                    We offer a wide variety of custom woodworking options including:<br /><br />

                    Live-Edge &amp; Epoxy Products<br />
                    Wood Countertops<br />
                    Furniture<br />
                    Custom Built-ins<br />
                    Shelving<br />
                    Pet Furniture (Indoor &amp; Outdoor)<br />
                    Kitchen Islands<br />
                    Custom Closet Systems<br /><br />

                    Please contact us today via social media, email, phone or text to discuss your custom woodworking needs.

                </div>

            </div>
        </div>

    );

};

export default CustomWoodworking;
