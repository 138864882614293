import React from 'react'
import '../styles/Footer.css'

const Footer = () => {

    return (
        <div className='footer'>
            <div className='container'>
                <div className='col'>
                    <h5>Company</h5>
                    <span className='bar'></span>
                    <a href='/about'>About Us</a>
                </div>
                <div className='col'>
                    <h5>Support</h5>
                    <span className='bar'></span>
                    <a href='/contact'>Contact Us</a>
                </div>
                <div className='col'>
                    <h5>Feedback</h5>
                    <span className='bar'></span>
                    <a href='/testimonials'>Testimonials</a>
                </div>
            </div>
        </div>
    )

}

export default Footer 
