
import '../styles/ImageGrid.css';

/* Full Size Images for Link */
import Shop001 from "../assets/Gallery/SHOP-001.webp";
import Shop002 from "../assets/Gallery/SHOP-002.webp";
import Shop003 from "../assets/Gallery/SHOP-003.webp";
import Shop004 from "../assets/Gallery/SHOP-004.webp";
import Shop005 from "../assets/Gallery/SHOP-005.webp";
import Shop006 from "../assets/Gallery/SHOP-006.webp";
import Shop007 from "../assets/Gallery/SHOP-007.webp";

import Shop008 from "../assets/Gallery/SHOP-008.webp";
import Shop009 from "../assets/Gallery/SHOP-009.webp";
import Shop010 from "../assets/Gallery/SHOP-010.webp";
import Shop011 from "../assets/Gallery/SHOP-011.webp";
import Shop012 from "../assets/Gallery/SHOP-012.webp";
import Shop013 from "../assets/Gallery/SHOP-013.webp";

import Shop014 from "../assets/Gallery/SHOP-014.webp";
import Shop015 from "../assets/Gallery/SHOP-015.webp";
import Shop016 from "../assets/Gallery/SHOP-016.webp";
import Shop017 from "../assets/Gallery/SHOP-017.webp";
import Shop018 from "../assets/Gallery/SHOP-018.webp";
import Shop019 from "../assets/Gallery/SHOP-019.webp";

import Shop020 from "../assets/Gallery/SHOP-020.webp";
import Shop021 from "../assets/Gallery/SHOP-021.webp";
import Shop022 from "../assets/Gallery/SHOP-022.webp";
import Shop023 from "../assets/Gallery/SHOP-023.webp";
import Shop024 from "../assets/Gallery/SHOP-024.webp";
import Shop025 from "../assets/Gallery/SHOP-025.webp";
import Shop026 from "../assets/Gallery/SHOP-026.webp";
import Shop027 from "../assets/Gallery/SHOP-027.webp";
import Shop028 from "../assets/Gallery/SHOP-028.webp";
import Shop029 from "../assets/Gallery/SHOP-029.webp";
import Shop030 from "../assets/Gallery/SHOP-030.webp";
import Shop031 from "../assets/Gallery/SHOP-031.webp";
import Shop032 from "../assets/Gallery/SHOP-032.webp";


/* Minimized Images for Gallery Display */
import Shop001Min from "../assets/Gallery/SHOP-001-min2.webp";
import Shop002Min from "../assets/Gallery/SHOP-002-min2.webp";
import Shop003Min from "../assets/Gallery/SHOP-003-min2.webp";
import Shop004Min from "../assets/Gallery/SHOP-004-min2.webp";
import Shop005Min from "../assets/Gallery/SHOP-005-min2.webp";
import Shop006Min from "../assets/Gallery/SHOP-006-min2.webp";
import Shop007Min from "../assets/Gallery/SHOP-007-min2.webp";
import Shop008Min from "../assets/Gallery/SHOP-008-min2.webp";
import Shop009Min from "../assets/Gallery/SHOP-009-min2.webp";
import Shop010Min from "../assets/Gallery/SHOP-010-min2.webp";

import Shop011Min from "../assets/Gallery/SHOP-011-min2.webp";
import Shop012Min from "../assets/Gallery/SHOP-012-min2.webp";
import Shop013Min from "../assets/Gallery/SHOP-013-min2.webp";
import Shop014Min from "../assets/Gallery/SHOP-014-min2.webp";
import Shop015Min from "../assets/Gallery/SHOP-015-min2.webp";
import Shop016Min from "../assets/Gallery/SHOP-016-min2.webp";
import Shop017Min from "../assets/Gallery/SHOP-017-min2.webp";
import Shop018Min from "../assets/Gallery/SHOP-018-min2.webp";

import Shop019Min from "../assets/Gallery/SHOP-019-min2.webp";
import Shop020Min from "../assets/Gallery/SHOP-020-min2.webp";
import Shop021Min from "../assets/Gallery/SHOP-021-min2.webp";
import Shop022Min from "../assets/Gallery/SHOP-022-min2.webp";
import Shop023Min from "../assets/Gallery/SHOP-023-min2.webp";
import Shop024Min from "../assets/Gallery/SHOP-024-min2.webp";
import Shop025Min from "../assets/Gallery/SHOP-025-min2.webp";
import Shop026Min from "../assets/Gallery/SHOP-026-min2.webp";

import Shop027Min from "../assets/Gallery/SHOP-027-min2.webp";
import Shop028Min from "../assets/Gallery/SHOP-028-min2.webp";
import Shop029Min from "../assets/Gallery/SHOP-029-min2.webp";
import Shop030Min from "../assets/Gallery/SHOP-030-min2.webp";
import Shop031Min from "../assets/Gallery/SHOP-031-min2.webp";
import Shop032Min from "../assets/Gallery/SHOP-032-min2.webp";

const ShopGallery = () => {

    return (

        <div className="row"> 
            <div className="column">
                <a href={Shop001} target="_blank" rel="noreferrer">
                    <img src={Shop001Min} alt="Gallery 1"/>
                </a>
                <a href={Shop002} target="_blank" rel="noreferrer">
                    <img src={Shop002Min} alt="Gallery 2"/>
                </a>
                <a href={Shop003} target="_blank" rel="noreferrer">
                    <img src={Shop003Min} alt="Gallery 3"/>
                </a>
                <a href={Shop004} target="_blank" rel="noreferrer">
                    <img src={Shop004Min} alt="Gallery 4"/>
                </a>
                <a href={Shop005} target="_blank" rel="noreferrer">
                    <img src={Shop005Min} alt="Gallery 5"/>
                </a>
                <a href={Shop006} target="_blank" rel="noreferrer">
                    <img src={Shop006Min} alt="Gallery 6"/>
                </a>
                <a href={Shop007} target="_blank" rel="noreferrer">
                    <img src={Shop007Min} alt="Gallery 7"/>
                </a>
                <a href={Shop008} target="_blank" rel="noreferrer">
                    <img src={Shop008Min} alt="Gallery 8"/>
                </a>
                <a href={Shop009} target="_blank" rel="noreferrer">
                    <img src={Shop009Min} alt="Gallery 9"/>
                </a>
                <a href={Shop010} target="_blank" rel="noreferrer">
                    <img src={Shop010Min} alt="Gallery 10"/>
                </a>
            </div>

            <div className="column">
                <a href={Shop011} target="_blank" rel="noreferrer">
                    <img src={Shop011Min} alt="Gallery 11"/>
                </a>
                <a href={Shop012} target="_blank" rel="noreferrer">
                    <img src={Shop012Min} alt="Gallery 12"/>
                </a>
                <a href={Shop013} target="_blank" rel="noreferrer">
                    <img src={Shop013Min} alt="Gallery 13"/>
                </a>
                <a href={Shop014} target="_blank" rel="noreferrer">
                    <img src={Shop014Min} alt="Gallery 14"/>
                </a>
                <a href={Shop015} target="_blank" rel="noreferrer">
                    <img src={Shop015Min} alt="Gallery 15"/>
                </a>
                <a href={Shop016} target="_blank" rel="noreferrer">
                    <img src={Shop016Min} alt="Gallery 16"/>
                </a>
                <a href={Shop017} target="_blank" rel="noreferrer">
                    <img src={Shop017Min} alt="Gallery 17"/>
                </a>
                <a href={Shop018} target="_blank" rel="noreferrer">
                    <img src={Shop018Min} alt="Gallery 18"/>
                </a>
            </div>

            <div className="column">
                <a href={Shop019} target="_blank" rel="noreferrer">
                    <img src={Shop019Min} alt="Gallery 19"/>
                </a>
                <a href={Shop020} target="_blank" rel="noreferrer">
                    <img src={Shop020Min} alt="Gallery 20"/>
                </a>
                <a href={Shop021} target="_blank" rel="noreferrer">
                    <img src={Shop021Min} alt="Gallery 21"/>
                </a>
                <a href={Shop022} target="_blank" rel="noreferrer">
                    <img src={Shop022Min} alt="Gallery 22"/>
                </a>
                <a href={Shop023} target="_blank" rel="noreferrer">
                    <img src={Shop023Min} alt="Gallery 23"/>
                </a>
                <a href={Shop024} target="_blank" rel="noreferrer">
                    <img src={Shop024Min} alt="Gallery 24"/>
                </a>
                <a href={Shop025} target="_blank" rel="noreferrer">
                    <img src={Shop025Min} alt="Gallery 25"/>
                </a>
                <a href={Shop026} target="_blank" rel="noreferrer">
                    <img src={Shop026Min} alt="Gallery 26"/>
                </a>
            </div>

            <div className="column">
                <a href={Shop027} target="_blank" rel="noreferrer">
                    <img src={Shop027Min} alt="Gallery 27"/>
                </a>
                <a href={Shop028} target="_blank" rel="noreferrer">
                    <img src={Shop028Min} alt="Gallery 28"/>
                </a>
                <a href={Shop029} target="_blank" rel="noreferrer">
                    <img src={Shop029Min} alt="Gallery 29"/>
                </a>
                <a href={Shop030} target="_blank" rel="noreferrer">
                    <img src={Shop030Min} alt="Gallery 30"/>
                </a>
                <a href={Shop031} target="_blank" rel="noreferrer">
                    <img src={Shop031Min} alt="Gallery 31"/>
                </a>
                <a href={Shop032} target="_blank" rel="noreferrer">
                    <img src={Shop032Min} alt="Gallery 32"/>
                </a>
            </div>
        </div>

    );

};

export default ShopGallery;
