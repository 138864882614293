import React from 'react';

import '../styles/Components.css';

import Socials from '../components/Socials';

const Store = () => {

    return (

        <div className='components'>
            <div className='container'>

                <h1>Store</h1>

                <Socials />
                
                <div className="container-small">
                    <h1>Crystal Wood Elements Store Coming Soon!</h1>
                </div>    

            </div>
        </div>

    );

};

export default Store;
